import React, { useState } from 'react'
import classNames from 'classnames'

import Link from './link'
import CodeBlock from './CodeBlock'
import URLS from '../urls'

const Paragraph = ({ children }) => <p className="note">{children}</p>

const InstructionsCodeBlock = ({ language = 'bash', ...rest }) => (
  <CodeBlock embedded language={language} {...rest} />
)

const SwiftInstructions = ({ docsURL }) => (
  <>
    <Paragraph>
      Add our agent to your <code>Podfile</code> on your test target(s):
    </Paragraph>
    <InstructionsCodeBlock
      language="ruby"
      code={`target 'MyAppTests' do
  pod 'ScopeAgent'
end`}
    />

    <Paragraph>
      Then, set the required environment variables (
      <Link href={docsURL}>see how in our documentation</Link>).
    </Paragraph>

    <Paragraph>
      After this, you can run your tests as you normally do. For example:
    </Paragraph>
    <InstructionsCodeBlock code="xcodebuild test" />
  </>
)
const PythonInstructions = () => (
  <>
    <Paragraph>
      Install our agent via <code>pip</code>:
    </Paragraph>
    <InstructionsCodeBlock code="pip install -U scopeagent" />

    <Paragraph>Then, set the Scope DSN as an environment variable:</Paragraph>
    <InstructionsCodeBlock code="export SCOPE_DSN=REPLACE_WITH_DSN" />

    <Paragraph>
      After this, you can run your tests prefixed by <code>scope-run</code>:
    </Paragraph>
    <InstructionsCodeBlock code="scope-run python -m unittest discover" />
  </>
)
const DotNetInstructions = () => (
  <>
    <Paragraph>
      Install our agent via NuGet as a .NET Core CLI Global tool:
    </Paragraph>
    <InstructionsCodeBlock code="dotnet tool install --global ScopeAgent.Runner" />

    <Paragraph>Then, set the Scope DSN as an environment variable:</Paragraph>
    <InstructionsCodeBlock code="export SCOPE_DSN=REPLACE_WITH_DSN" />

    <Paragraph>
      After this, you can run your tests prefixed by <code>scope-run</code>:
    </Paragraph>
    <InstructionsCodeBlock code="scope-run dotnet test" />
  </>
)
const JavaInstructions = ({ docsURL }) => (
  <>
    <Paragraph>
      Add our agent as a dependency to your <strong>Maven</strong> or{' '}
      <strong>Gradle</strong> project. For example, in your <code>pom.xml</code>
      :
    </Paragraph>
    <InstructionsCodeBlock
      language="xml"
      code={`<properties>
  <scope.agent.version>0.2.4</scope.agent.version>
</properties>
...
<dependency>
  <groupId>com.undefinedlabs.scope</groupId>
  <artifactId>scope-agent</artifactId>
  <version>\${scope.agent.version}</version>
  <scope>provided</scope>
</dependency>`}
    />

    <Paragraph>
      Then, configure a Maven plugin or Gradle task to use our agent (
      <Link href={docsURL}>see how in our documentation</Link>).
    </Paragraph>

    <Paragraph>
      Finally, set the Scope DSN as an environment variable:
    </Paragraph>
    <InstructionsCodeBlock code="export SCOPE_DSN=REPLACE_WITH_DSN" />

    <Paragraph>
      After this, you can run your tests as you normally do. For example, with
      Maven:
    </Paragraph>
    <InstructionsCodeBlock code="mvn clean verify" />
  </>
)
const GoInstructions = ({ docsURL }) => (
  <>
    <Paragraph>
      Install our agent via <code>go get</code>:
    </Paragraph>
    <InstructionsCodeBlock code="go get -u go.undefinedlabs.com/scopeagent" />

    <Paragraph>Then, set the Scope DSN as an environment variable:</Paragraph>
    <InstructionsCodeBlock code="export SCOPE_DSN=REPLACE_WITH_DSN" />

    <Paragraph>
      Finally, follow the steps to instrument your tests (
      <Link href={docsURL}>see how in our documentation</Link>).
    </Paragraph>

    <Paragraph>
      After this, you can run your tests as you normally do. For example:
    </Paragraph>
    <InstructionsCodeBlock code="go test ./..." />
  </>
)
const JavaScriptInstructions = ({ docsURL }) => (
  <>
    <Paragraph>
      Install our agent via <code>npm</code>:
    </Paragraph>
    <InstructionsCodeBlock code="npm install --save-dev @undefinedlabs/scope-agent" />

    <Paragraph>
      Then, set the Scope DSN and import our agent in <strong>Jest</strong> or{' '}
      <strong>Cypress</strong> configuration files (
      <Link href={docsURL}>see how in our documentation</Link>).
    </Paragraph>

    <Paragraph>
      After this, you can run your tests as you normally do. For example:
    </Paragraph>
    <InstructionsCodeBlock code="npm test" />
  </>
)

const NodeJsInstructions = ({ docsURL }) => (
  <>
    <Paragraph>
      Install our agent via <code>npm</code>:
    </Paragraph>
    <InstructionsCodeBlock code="npm install --save-dev @undefinedlabs/scope-agent" />

    <Paragraph>
      Then, set the Scope DSN and import our agent in <strong>Jest</strong> (
      <Link href={docsURL}>see how in our documentation</Link>).
    </Paragraph>

    <Paragraph>
      After this, you can run your tests as you normally do. For example:
    </Paragraph>
    <InstructionsCodeBlock code="npm test" />
  </>
)

const tabs = [
  {
    label: 'Java',
    instructions: JavaInstructions,
    docsURL: `${URLS.SCOPE.DOCS}/java-installation`,
  },
  {
    label: 'JavaScript',
    instructions: JavaScriptInstructions,
    docsURL: `${URLS.SCOPE.DOCS}/javascript-installation`,
  },
  {
    label: 'Node.js',
    instructions: NodeJsInstructions,
    docsURL: `${URLS.SCOPE.DOCS}/nodejs-installation`,
  },
  {
    label: 'Python',
    instructions: PythonInstructions,
    docsURL: `${URLS.SCOPE.DOCS}/python-installation`,
  },
  {
    label: '.NET',
    instructions: DotNetInstructions,
    docsURL: `${URLS.SCOPE.DOCS}/dotnet-installation`,
  },
  {
    label: 'Swift',
    instructions: SwiftInstructions,
    docsURL: `${URLS.SCOPE.DOCS}/swift-installation`,
  },
  {
    label: 'Go',
    instructions: GoInstructions,
    docsURL: `${URLS.SCOPE.DOCS}/go-installation`,
  },
]
const useInstructionsTabs = () => {
  const [selectedTab, setSelectedTab] = useState(tabs[0])

  return {
    tabs: tabs.map((tab) => ({
      label: tab.label,
      isSelected: tab.label === selectedTab.label,
      onSelect: () => setSelectedTab(tab),
    })),
    ...selectedTab,
  }
}

const SetupInstructions = ({ className }) => {
  const { tabs, instructions: Instructions, docsURL } = useInstructionsTabs()

  return (
    <div className={classNames('instructions-block', className)}>
      <div className="tabs">
        {tabs.map(({ label, isSelected, onSelect }) => (
          <label key={label} className={isSelected ? 'selected' : undefined}>
            {label}
            <input
              type="radio"
              value={label}
              onChange={onSelect}
              checked={isSelected}
              hidden
            />
          </label>
        ))}
      </div>

      <div className="content">
        <Instructions className="code" docsURL={docsURL} />

        <Paragraph>
          For more information you may refer to{' '}
          <Link href={docsURL}>our documentation</Link>.
        </Paragraph>
      </div>
    </div>
  )
}

export default SetupInstructions
