import React from 'react'

const SetupIllustration = () => (
  <svg
    className="illustration"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 491 126"
  >
    <g className="icons">
      <g>
        <path
          fill="#99A1C1"
          d="M199.79 64.862c-7.5 1.323-6.456 4.51-6.456 4.51l.606 3.385 7.157-1.262.18 1.017-10 1.763s-4.896.3-3.555 7.907c1.342 7.607 5.483 6.599 5.483 6.599l2.5-.44-.622-3.531s-.878-4.187 3.379-4.938l7.099-1.251s4-.639 3.305-4.578l-1.149-6.514s-.089-4.049-7.927-2.667zm-3.545 2.974c.712-.126 1.39.351 1.516 1.067s-.348 1.396-1.06 1.521a1.309 1.309 0 01-1.515-1.067 1.309 1.309 0 011.059-1.521z"
        ></path>
        <path
          fill="#F1BD47"
          d="M205.21 94.368c7.5-1.322 6.455-4.51 6.455-4.51l-.606-3.385-7.157 1.262-.179-1.017 10-1.763s4.896-.3 3.554-7.907c-1.341-7.607-5.483-6.599-5.483-6.599l-2.5.441.623 3.53s.877 4.187-3.38 4.938l-7.099 1.252s-3.999.638-3.305 4.578l1.149 6.513s.089 4.05 7.928 2.667zm3.544-2.974a1.309 1.309 0 01-1.515-1.067 1.309 1.309 0 011.059-1.521c.712-.126 1.39.351 1.516 1.067s-.348 1.396-1.06 1.521z"
        ></path>
      </g>
      <g>
        <g fill="#fff">
          <path d="M322.94 83.029s-1.264.568.75 1.016c2.441.542 3.708.634 6.485.405 0 0 .669.524 1.628 1.018-6.379 1.974-13.798-1.603-8.863-2.439zM322.55 79.538s-1.44.849.579 1.274c2.61.55 4.692.791 8.372.476 0 0 .446.558 1.201.917-7.606 1.382-15.617-1.47-10.152-2.667z"></path>
          <path d="M336.29 86.985s.805.82-1.107 1.186c-3.635.697-14.809-.151-17.762-1.824-1.062-.6 1.095-1.053 1.766-1.125.7-.076 1.088-.007 1.088-.007-1.145-1.002-8.176.87-3.691 2.09 12.232 3.326 22.78 1.475 19.706-.32zM324.24 76.139s-5.808.75-2.2 1.624c1.524.37 4.61.646 7.504.707 2.366.049 4.765-.124 4.765-.124s-.864.267-1.505.613c-5.914.908-16.956-.964-13.593-2.176 2.844-1.024 5.029-.644 5.029-.644zM333.81 82.89c6.169-2.425 3.771-5.63 1.842-5.435-.473.048-.688.11-.688.11s.2-.252.541-.334c3.872-.92 6.199 4.567-1.829 5.8 0 0 .099-.072.134-.141z"></path>
          <path d="M323.35 90.119c5.577.95 14.258 1.297 14.741-1.338 0 0-.498.965-4.83 1.319-4.887.398-10.787-.329-14.232-1.275 0 0 .655.67 4.321 1.294z"></path>
        </g>
        <path
          fill="#E76F00"
          d="M332.68 59.676s2.899 3.581-3.937 7.9c-5.482 3.466-1.814 6.156-.936 8.881-2.674-2.976-4.596-5.55-2.912-7.582 2.472-2.981 8.551-3.914 7.785-9.199z"
        ></path>
        <path
          fill="#E76F00"
          d="M329.45 74.408c1.321 1.89-.741 3.248-.741 3.248s4.026-1.57 2.531-4.223c-1.396-2.474-2.532-3.75 4.683-6.979 0 0-10.954 1.544-6.473 7.954z"
        ></path>
      </g>
      <g clipPath="url(#c)">
        <g fill="#7833D0">
          <path d="M400.69 81.435a2.638 2.638 0 00-.328-1.332 2.544 2.544 0 00-.966-.941c-3.553-2.05-7.107-4.097-10.663-6.143-.958-.553-1.89-.533-2.836.027-1.415.835-8.497 4.892-10.603 6.115-.869.503-1.292 1.272-1.292 2.273v12.362c-.01.455.098.905.312 1.307.232.406.571.74.98.967 2.111 1.222 9.193 5.28 10.603 6.113.951.561 1.879.581 2.838.028 3.552-2.05 7.107-4.098 10.663-6.142.41-.226.749-.56.98-.967.214-.401.322-.851.312-1.307v-12.36z"></path>
          <path d="M387.39 87.575l-13.075 7.528c.231.406.57.74.98.967 2.11 1.222 9.193 5.279 10.602 6.113.951.561 1.88.581 2.839.028 3.552-2.051 7.106-4.098 10.663-6.142.409-.226.748-.56.98-.967l-12.989-7.527z"></path>
          <path d="M383.51 89.805a4.41 4.41 0 006.066 1.617 4.412 4.412 0 001.613-1.643l-3.806-2.204-3.873 2.23zM400.69 81.435a2.638 2.638 0 00-.328-1.332l-12.975 7.472 12.993 7.527c.214-.402.322-.852.312-1.307v-12.36"></path>
        </g>
        <g fill="#fff">
          <path d="M391.19 89.779a4.41 4.41 0 01-4.951 2.11 4.412 4.412 0 01-.056-8.532 4.414 4.414 0 014.979 2.044l3.858-2.22a8.868 8.868 0 00-9.982-4.122 8.867 8.867 0 00-6.557 8.582 8.862 8.862 0 006.601 8.547 8.867 8.867 0 009.961-4.174l-3.853-2.235zM396.07 85.519h-.877v4.247h.877v-4.247zM398.01 85.519h-.877v4.247h.877v-4.247z"></path>
          <path d="M398.72 87.108v-.877h-4.247v.877h4.247zM398.72 89.054v-.877h-4.247v.877h4.247z"></path>
        </g>
      </g>
      <g fill="#5EC7B0" clipPath="url(#b)">
        <path d="M427.51 93.52c-.089-.011-.106-.058-.053-.12l.541-.544c.053-.06.17-.092.259-.08l7.951.976c.089.01.103.08.051.141l-.45.533c-.053.061-.172.114-.239.106l-8.06-1.012zM423.9 95.156c-.089-.01-.106-.058-.053-.12l.542-.544c.052-.06.169-.092.258-.08l10.156 1.246c.089.011.126.084.095.148l-.244.512c-.033.087-.127.12-.216.11L423.9 95.155zM429.04 97.867c-.09-.01-.104-.08-.051-.142l.38-.518c.053-.062.15-.117.239-.107l4.455.547c.089.011.125.084.114.173l-.11.529c-.011.089-.108.145-.175.136l-4.852-.618zM452.71 96.206c-1.447.184-2.437.334-3.862.52-.345.049-.37.068-.618-.301-.288-.42-.503-.695-.943-.975-1.319-.863-2.702-.83-4.073-.161-1.634.794-2.592 2.146-2.791 3.952-.196 1.785.848 3.405 2.578 3.866 1.489.387 2.824.008 3.966-1.005.231-.22.442-.465.703-.75l-4.299-.528c-.467-.057-.543-.36-.341-.72.374-.655 1.051-1.747 1.434-2.288a.606.606 0 01.601-.288l8.107.995c-.119.596-.193 1.198-.356 1.788-.441 1.574-1.223 2.97-2.362 4.14-1.863 1.92-4.118 2.977-6.812 3.008-2.218.022-4.193-.65-5.811-2.206-1.495-1.45-2.228-3.212-2.221-5.291.008-2.463.989-4.559 2.694-6.293 1.836-1.878 4.091-2.935 6.709-3.088 2.14-.121 4.115.37 5.768 1.816 1.085.925 1.8 2.098 2.198 3.458.109.217.007.317-.269.351z"></path>
        <path d="M458.57 109.43c-2.021-.294-3.799-1.1-5.194-2.628-1.174-1.297-1.821-2.846-1.877-4.593-.092-2.566.872-4.709 2.629-6.505 1.889-1.938 3.985-2.811 6.693-2.954 2.321-.122 4.432.364 6.208 1.916 1.612 1.418 2.504 3.178 2.531 5.352.009 3.053-1.16 5.396-3.489 7.235-1.653 1.312-3.572 2.026-5.695 2.195-.615.037-1.219-.014-1.806-.018zm6.405-8.347c.014-.293.041-.515.024-.744-.13-2.254-2.004-3.75-4.18-3.52-2.129.214-3.626 1.364-4.373 3.397-.615 1.688.016 3.552 1.532 4.461 1.159.685 2.392.769 3.646.312 1.872-.697 3.005-2.005 3.351-3.906z"></path>
      </g>
      <g>
        <path
          fill="#F1BD47"
          d="M91.171 78.851l-25.739 3.677a2 2 0 00-1.697 2.263l3.677 25.739a2 2 0 002.263 1.697l25.739-3.677a2 2 0 001.697-2.263l-3.677-25.738a2 2 0 00-2.263-1.697z"
        ></path>
        <path
          fill="#000"
          d="M85.659 104c.588-.464 1.413-.328 2.047.072.542.344 1.186.511 2.006.394 1.156-.165 1.812-.848 1.698-1.647-.136-.957-.944-1.187-2.296-1.562l-.74-.2c-2.137-.57-3.628-1.454-3.953-3.726-.299-2.093 1.068-3.914 3.56-4.27 1.356-.194 2.472.014 3.436.786.538.43.445 1.234-.077 1.685-.566.489-1.39.29-2.093.034-.277-.1-.576-.12-.918-.072-.817.117-1.261.71-1.164 1.387.12.837.686 1.102 1.958 1.45l.74.199c2.516.678 4.003 1.523 4.336 3.854.36 2.513-1.418 4.171-4.069 4.55-1.983.283-3.533-.234-4.622-1.165-.555-.475-.423-1.316.151-1.769zm-9.873 1.722c.606-.496 1.427-.284 2.11.101.276.156.596.228.998.171.917-.131 1.444-.572 1.245-1.967l-1.156-8.094a1.41 1.41 0 112.79-.399l1.162 8.132c.413 2.889-1.094 4.447-3.567 4.8-1.641.234-2.864-.228-3.738-1.004-.53-.473-.394-1.29.156-1.74z"
        ></path>
      </g>
      <g clipPath="url(#a)">
        <path
          fill="url(#paint0_linear)"
          d="M146.82 88.231c-2.04 3.535-6.143 6.146-11.424 6.776-4.269.52-8.588-.39-12.095-2.255 1.296.224 2.654.219 4.043.097 5.555-.475 9.845-3.139 11.822-6.818l-.008-.003c-6.312-.828-12.558-3.057-17.392-5.03-.921-.247-1.779-.723-2.596-1.127 5.123 1.126 12.679 1.917 15.206 1.917-6.452-1.915-13.058-5.102-12.828-5.04 10.05 2.754 18.063 2.99 18.063 2.99a7.99 7.99 0 01.632.014c-.06-.399-.143-.801-.251-1.206-1.126-4.309-4.735-7.866-9.309-9.892 7.97.163 15.345 4.85 17.128 11.309.05.173.095.347.135.52.029.01.058.02.087.033 5.022 1.962 6.452 6.29 5.666 5.772-3.37-2.183-5.752.455-6.879 1.943z"
        ></path>
      </g>
      <g>
        <path
          fill="#7833D0"
          d="M275.76 58.876h-26a2 2 0 00-2 2v26a2 2 0 002 2h26a2 2 0 002-2v-26a2 2 0 00-2-2z"
        ></path>
        <path
          fill="#fff"
          stroke="#fff"
          strokeWidth="0.3"
          d="M252.14 83.679c0 .19-.068.353-.204.49a.67.67 0 01-.494.208.643.643 0 01-.482-.207.686.686 0 01-.198-.491c0-.195.066-.358.198-.494a.645.645 0 01.482-.204c.193 0 .358.068.494.207a.677.677 0 01.204.491zm8.646.556h-1.259l-4.572-7.06a3.215 3.215 0 01-.277-.556h-.038c.033.189.049.594.049 1.216v6.4h-1.038v-8.858h1.348l4.435 6.949a8.8 8.8 0 01.358.592h.025c-.042-.255-.063-.69-.063-1.302v-6.239h1.032v8.858zm6.656 0h-4.695v-8.858h4.491v.939h-3.453v2.946h3.2v.94h-3.201v3.093h3.658v.94zm7.12-7.92h-2.551v7.92h-1.038v-7.92h-2.556v-.938h6.145v.939z"
        ></path>
      </g>
    </g>
    <g className="decorations" fill="#727EAD" stroke="#727EAD">
      <circle cx="3.328" cy="60.445" r="1.828" strokeWidth="2"></circle>
      <circle cx="34.87" cy="81.236" r="2.328"></circle>
      <circle cx="81.298" cy="41.152" r="2.328"></circle>
      <g>
        <rect
          width="8.191"
          height="1.121"
          x="31.17"
          y="39.629"
          rx="0.56"
          transform="rotate(50 31.17 39.629)"
        ></rect>
      </g>
      <circle cx="412.1" cy="42.566" r="1.828" strokeWidth="2"></circle>
      <circle cx="487.95" cy="60.446" r="2.328"></circle>
      <g>
        <rect
          width="8.191"
          height="1.121"
          x="451.95"
          y="39.859"
          rx="0.56"
          transform="rotate(120 451.95 39.859)"
        ></rect>
      </g>
      <circle cx="161.33" cy="21.446" r="1.828" strokeWidth="2"></circle>
      <circle cx="281.1" cy="27.566" r="1.828" strokeWidth="2"></circle>
      <circle cx="129.87" cy="33.236" r="2.328"></circle>
      <circle cx="336.95" cy="13.446" r="2.328"></circle>
      <circle cx="220.3" cy="26.152" r="2.328"></circle>
      <path d="M246.9 6.115l2.598-4.5 2.598 4.5H246.9z"></path>
      <circle cx="369.67" cy="35.138" r="1.828" strokeWidth="2"></circle>
      <g>
        <rect
          width="8.191"
          height="1.121"
          x="300.95"
          y="4.859"
          rx="0.56"
          transform="rotate(120 300.95 4.859)"
        ></rect>
      </g>
      <g>
        <rect
          width="8.191"
          height="1.121"
          x="190.17"
          y="4.629"
          rx="0.56"
          transform="rotate(50 190.17 4.629)"
        ></rect>
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="127.7"
        x2="141.14"
        y1="70.375"
        y2="93.656"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#F88A36"></stop>
        <stop offset="1" stopColor="#FD2020"></stop>
      </linearGradient>
      <clipPath id="c">
        <path
          fill="#fff"
          d="M0 0H30V30H0z"
          transform="translate(372.5 72.615)"
        ></path>
      </clipPath>
      <clipPath id="b">
        <path
          fill="#fff"
          d="M0 0H46V46H0z"
          transform="rotate(7 -391.574 3523.435)"
        ></path>
      </clipPath>
      <clipPath id="a">
        <path
          fill="#fff"
          d="M0 0H30V30H0z"
          transform="rotate(-30 201.922 -177.915)"
        ></path>
      </clipPath>
    </defs>
  </svg>
)

export default SetupIllustration
