import React from 'react'
import classNames from 'classnames'

const addClassNameToElement = (element, className) =>
  React.cloneElement(element, {
    className: classNames(element.props.className, className),
  })

const StripSection = ({ className, children: [inside, overflown, note] }) => (
  <>
    <section className={classNames('strip full-width centered', className)}>
      <div className="strip full-width">
        <svg
          className="angle"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <polygon points="0,99 100,0 100,99 100,100 0,100" />
        </svg>

        {addClassNameToElement(inside, 'inside')}

        <svg
          className="angle"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <polygon points="100,0 100,1 0,100 0,1 0,0" />
        </svg>
      </div>

      <div>{addClassNameToElement(overflown, 'overflown')}</div>
    </section>

    {note && addClassNameToElement(note, 'note footnote centered')}
  </>
)

export default StripSection
