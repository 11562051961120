import React from 'react'
import PropTypes from 'prop-types'
import Highlight, { defaultProps, Prism } from 'prism-react-renderer'
import classNames from 'classnames'

import './GithubTheme.scss'

const CodeBlock = ({
  code,
  startLine,
  activeLines,
  language,
  embedded = false,
}) => (
  <Highlight
    {...defaultProps}
    theme={undefined}
    code={code}
    language={language}
  >
    {({ className, style, tokens, getLineProps, getTokenProps }) => {
      const preCode = (
        <pre className={classNames(className, { embedded })} style={style}>
          {tokens.map((line, i) => (
            <div
              {...getLineProps({
                line,
                key: i,
                className: classNames({
                  active:
                    activeLines &&
                    i >= activeLines.start - startLine &&
                    i <= activeLines.end - startLine,
                }),
              })}
            >
              {line.map((token, key) => (
                <span {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </pre>
      )
      return (
        <div className="d-flex flex-row code-block">
          {!embedded && (
            <div className="line-numbers">
              {tokens.map((li, i) => (
                <div key={i}>{startLine + i}</div>
              ))}
            </div>
          )}
          <div className="u-flex-1">{preCode}</div>
        </div>
      )
    }}
  </Highlight>
)

CodeBlock.propTypes = {
  code: PropTypes.string,
  startLine: PropTypes.number.isRequired,
  activeLines: PropTypes.shape({
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired,
  }),
  language: PropTypes.oneOf(Object.keys(Prism.languages)),
  embedded: PropTypes.bool,
}

CodeBlock.defaultProps = {
  code: '',
  startLine: 0,
}

export default CodeBlock
